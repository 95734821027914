import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Doku() {

  const data = useStaticQuery(graphql`{
  doku: file(relativePath: {eq: "doku-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  form1: file(relativePath: {eq: "doku-form1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 650, layout: CONSTRAINED)
    }
  }
  form2: file(relativePath: {eq: "doku-form2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 650, layout: CONSTRAINED)
    }
  }
}
`)




  return (
    <Layout>
      <SEO title="Referencie" />

      <div className="section-pd section-pd-bottom white-bg">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <h1>Web aplikácia pre Rakúsku spoločnosť Die Realität Immobilien.</h1>
              <p>Vytvorili sme webovú aplikáciu, ktorá ma za úlohu zbierať dokumentáciu k projektom spoločnosti. Dokumenty sú k dispozícii naprieč spoločnosťou a zákazníkov spoločnosti. Tí tak majú k dispozicícii všetky informácie na jednom mieste.</p>
            </div>
            <div className="col-12"><GatsbyImage
              image={data.doku.childImageSharp.gatsbyImageData}
              objectFit="cover"
              objectPosition="0% 0%"
              alt="Web stránka" />  </div>
          </div>
          <div className="row">
            <div className="col-6"><GatsbyImage
              image={data.form1.childImageSharp.gatsbyImageData}
              objectFit="cover"
              objectPosition="0% 0%"
              alt="Webová aplikácia" />  </div>
            <div className="col-6"><GatsbyImage
              image={data.form2.childImageSharp.gatsbyImageData}
              objectFit="cover"
              objectPosition="0% 0%"
              alt="Web aplikácia" />  </div>
          </div>
        </div>
      </div>
     
         
          

      
      



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/helske" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
